.container {
  composes: container from '../InstructionCard/InstructionCard.module.css';
}

.containerExpanded {
  composes: containerExpanded from '../InstructionCard/InstructionCard.module.css';
}

.heading {
  composes: heading from '../InstructionCard/InstructionCard.module.css';
}

.headingExpanded {
  composes: headingExpanded from '../InstructionCard/InstructionCard.module.css';
}

.content {
  composes: content from '../InstructionCard/InstructionCard.module.css';
}

@media (width <= 500px) {
  .content {
    padding-bottom: 3.25rem;
  }
}

.subHeading {
  composes: subHeading from '../InstructionCard/InstructionCard.module.css';
}

.stepsList {
  composes: stepsList from '../InstructionCard/InstructionCard.module.css';
  padding: 0;
}

.stepItem {
  composes: stepItem from '../InstructionCard/InstructionCard.module.css';
}

.linkToGuide {
  composes: linkToGuide from '../InstructionCard/InstructionCard.module.css';
}

.collapseBtn {
  composes: collapseBtn from '../InstructionCard/InstructionCard.module.css';
}

.bulletList {
  display: flex;
  flex-direction: column;
  list-style-position: inside;
  margin-top: 0.75rem;
  line-height: 1.5rem;
  padding: 0;
}

.indented {
  padding-left: 1.75rem;
}

@media (width <= 700px) {
  .indented {
    padding: 0;
  }
}
