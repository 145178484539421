.selectContainer {
  display: flex;
  color: white;
  margin-top: 10px;
  box-sizing: border-box;
  height: 60px;
  border: 1px solid rgb(var(--color-spanish-grey-rgb) / 50%);
  border-radius: 15px;
  background: rgb(0 0 0 / 10%);
  box-shadow: inset 2px 2px 6px 0 rgb(0 0 0/10%);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.selectContainer::after {
  content: '';
  background: url('../../ImageAssets/bte_Triangle.svg') no-repeat right/contain;
  width: 20px;
  height: 20px;
}

.selectContainerOpen {
  composes: selectContainer;
  background: rgb(0 0 0 / 10%);
  border-radius: 15px 15px 0 0;
}

.selectContainerOpen::after {
  content: '';
  background: url('../../ImageAssets/bte_Triangle.svg') no-repeat right/contain;
  transform: rotate(180deg);
}

.selection {
  height: 58px;
  width: 90%;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.optionBoxContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: white;
  margin-top: -10px;
  border-radius: 0 0 15px 15px;
  box-shadow: inset 2px 2px 6px 0 rgb(black / 10%);
}

.optionsWrapper {
  height: 60px;
  width: 100%;
  margin-top: 2px;
  background-color: var(--color-lily-green);
  color: var(--color-burgundy);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.optionsWrapper:hover {
  background-color: var(--color-olive);
  color: white;
}

.optionsWrapper:last-child {
  border-radius: 0 0 15px 15px;
}

.options {
  height: 58px;
  line-height: 58px;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: calc(535rem / 16);
}

.arrow {
  margin-left: auto;
}

.arrowRotate {
  composes: arrow;
  transform: rotate(180deg);
}
