.container {
  composes: container from '../InstructionCard/InstructionCard.module.css';
}

.containerExpanded {
  composes: containerExpanded from '../InstructionCard/InstructionCard.module.css';
}

.heading {
  composes: heading from '../InstructionCard/InstructionCard.module.css';
}

.headingExpanded {
  composes: headingExpanded from '../InstructionCard/InstructionCard.module.css';
}

.content {
  composes: content from '../InstructionCard/InstructionCard.module.css';
}

.subHeading {
  composes: subHeading from '../InstructionCard/InstructionCard.module.css';
}

.stepsList {
  composes: stepsList from '../InstructionCard/InstructionCard.module.css';
}

.stepItem {
  composes: stepItem from '../InstructionCard/InstructionCard.module.css';
}

.linkToGuide {
  composes: linkToGuide from '../InstructionCard/InstructionCard.module.css';
}

.collapseBtn {
  composes: collapseBtn from '../InstructionCard/InstructionCard.module.css';
}

.indented {
  composes: indented from '../InstructionCard/InstructionCard.module.css';
}
