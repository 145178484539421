.container {
  composes: container from '../InstructionCard/InstructionCard.module.css';
}

.containerExpanded {
  composes: containerExpanded from '../InstructionCard/InstructionCard.module.css';
}

.heading {
  composes: heading from '../InstructionCard/InstructionCard.module.css';
}

.headingExpanded {
  composes: headingExpanded from '../InstructionCard/InstructionCard.module.css';
}

.content {
  composes: content from '../InstructionCard/InstructionCard.module.css';
}

.subHeading {
  composes: subHeading from '../InstructionCard/InstructionCard.module.css';
}

.linkToGuide {
  composes: linkToGuide from '../InstructionCard/InstructionCard.module.css';
}

.collapseBtn {
  composes: collapseBtn from '../InstructionCard/InstructionCard.module.css';
}

.extensionWrapper {
  display: flex;
  gap: 10px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  max-width: 550px;
  flex-wrap: wrap;
}

@media (width <= 700px) {
  .extensionWrapper {
    justify-content: center;
  }
}

.extension {
  height: calc(75.6rem / 16);
  width: calc(250rem / 16);
  box-shadow: 0 6px 8px 0 rgb(black / 25%);
}

.chromeExtension {
  composes: extension;
  background: url('../../ImageAssets/chrome_webstore.svg') no-repeat;
}

.firefoxExtension {
  composes: extension;
  background: url('../../ImageAssets/firefox_webstore.svg') no-repeat;
}

.hiddenLinkText {
  visibility: hidden;
}

.indented {
  composes: indented from '../InstructionCard/InstructionCard.module.css';
}
