.container {
  width: 100%;
  display: flex;
  place-content: center;
  background: linear-gradient(180deg, white 0%, var(--color-light-blue) 100%);
  flex: 1;
}

.content {
  max-width: 1250px;
  width: 82%;
  letter-spacing: 0.1px;
  line-height: 22px;
  color: var(--colors-dark);
  font-size: 14px;
  padding: 36px 0 100px;
}

.heading {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.13px;
  margin: 0 0 27px;
}

.sectionHeading {
  font-size: 14px;
  margin: 16px 0;
}

.listHeading {
  composes: sectionHeading;
}

.anchor {
  text-decoration: none;
}
