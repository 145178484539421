.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0.75rem;
  box-shadow: 0 0.375rem 0.5rem 0 rgb(0 0 0 / 25%);
  margin-bottom: 1.25rem;
  position: relative;
  background:
    url('../../ImageAssets/Open.svg') no-repeat right 0.5rem top 1.125rem,
    var(--color-emerald);
}

.containerExpanded {
  composes: container;
  background: var(--color-emerald);
}

.heading {
  composes: heading from '../ClaimWeb3name/ClaimWeb3name.module.css';
}

.headingExpanded {
  composes: heading;
  cursor: default;
}

.content {
  composes: content from '../ClaimWeb3name/ClaimWeb3name.module.css';
  max-width: calc(41.875rem);
}

@media (width <= 500px) {
  .content {
    padding-bottom: 3.25rem;
  }
}

.subHeading {
  composes: subHeading from '../ClaimWeb3name/ClaimWeb3name.module.css';
}

.stepsList {
  display: flex;
  flex-direction: column;
  list-style-position: inside;
  padding-left: 1.75rem;
  margin-top: 0.75rem;
}

@media (width <= 700px) {
  .stepsList {
    padding: 0;
  }
}

.stepItem {
  line-height: 24px;
  margin-bottom: 1.25rem;
}

.stepInfo {
  margin-top: 1rem;
  margin-bottom: 0;
}

.error {
  composes: stepInfo;
  color: var(--color-pixel-red);
}

.stepInfoImportant {
  composes: stepInfo;
  font-weight: bold;
}

.inputContainer {
  display: flex;
  margin-top: 10px;
  max-width: calc(535rem / 16);
  box-sizing: border-box;
  height: 60px;
  border: 1px solid rgb(var(--color-spanish-grey-rgb) / 50%);
  border-radius: 15px;
  background-color: rgb(0 0 0 / 10%);
  box-shadow: inset 2px 2px 6px 0 rgb(0 0 0 / 30%);
  align-items: center;
  justify-content: center;
}

.input {
  height: 58px;
  width: 90%;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  background-color: transparent;
  border: none;
  color: white;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: white;
}

.collapseBtn {
  composes: collapseBtn from '../ClaimWeb3name/ClaimWeb3name.module.css';
}

.connectBtn {
  display: inline-flex;
  color: white;
  margin-top: 10px;
  width: 100%;
  max-width: calc(535rem / 16);
  height: 60px;
  border-radius: 15px;
  border: 1px solid white;
  background: var(--color-olive);
  box-shadow: 0 6px 8px 0 rgb(0 0 0/25%);
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.connectBtn:disabled {
  color: rgb(255 255 255 /30%);
  background:
    url('../../ImageAssets/bte_check.svg') no-repeat 98% center,
    var(--color-olive);
  opacity: 0.6;
  border: 1px solid var(--color-wall-green);
}

@media (width <= 400px) {
  .connectBtn {
    font-size: 16px;
  }
}

.connectBtnLoader {
  composes: connectBtn;
  background:
    url('../../ImageAssets/oval.svg') no-repeat 98% center,
    var(--color-olive);
}
