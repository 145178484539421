.linkingBtn {
  display: inline-flex;
  color: white;
  margin-top: 10px;
  width: 100%;
  max-width: calc(535rem / 16);
  height: 60px;
  border-radius: 15px;
  border: 1px solid white;
  box-shadow: 0 6px 8px 0 rgb(0 0 0/25%);
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background: var(--color-olive);
}

.linkingBtn:disabled {
  color: rgb(255 255 255 /30%);
  background: var(--color-olive);
  opacity: 0.6;
  border: 1px solid var(--color-wall-green);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.successLabel {
  color: green;
  margin-top: 1rem;
}

.errorLabel {
  color: red;
  margin-top: 1rem;
}

.loader {
  composes: linkingBtn;
  background: url('../../ImageAssets/oval.svg') no-repeat 98% 50%;
}
