.container {
  width: 100%;
  display: flex;
  place-content: center;
  background: linear-gradient(180deg, white 0%, var(--color-light-blue) 100%);
  flex: 1;
}

.content {
  max-width: 1250px;
  width: 82%;
  letter-spacing: 0.1px;
  line-height: 22px;
  color: var(--color-dark);
  font-size: 14px;
  padding: 36px 0 100px;
}

.heading {
  font-size: 24px;
  margin: 0 0 60px;
}

.imprintSection {
  margin: 16px 0 0;
}

.imprintLine {
  margin: 0;
}

.anchor {
  text-decoration: none;
}
